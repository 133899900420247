//export const BASE_URL = "https://carp.cv.ua:7443/enforcer";
//export const BASE_URL = "https://parkua.desyde.ua:7443/enforcer";
//export const BASE_URL = process.env.REACT_APP_BASE_URL? process.env.REACT_APP_BASE_URL : '';
let urls = {
    klsh:
        process.env.REACT_APP_ENV === "dev"
            ? "https://parkua.desyde.ua"
            : "https://kalush.carp.ua",
    kmr:
        process.env.REACT_APP_ENV === "dev"
            ? "https://parkua.desyde.ua"
            : "https://kmr.carp.ua",
    nadv:
        process.env.REACT_APP_ENV === "dev"
            ? "https://parkua.desyde.ua"
            : "https://nadv.carp.ua",
    cv:
        process.env.REACT_APP_ENV === "dev"
            ? "https://parkua.desyde.ua"
            : process.env.REACT_APP_ENV === "demo" 
            ? "https://demo.carp.ua" 
            : "https://carp.cv.ua",
    ivf:
        process.env.REACT_APP_ENV === "dev"
            ? "https://parkua.desyde.ua"
            : "https://carp.cv.ua",
};

export const BASE_URL =
    process.env.REACT_APP_CITY in urls
        ? urls[process.env.REACT_APP_CITY]
        : "https://parkua.desyde.ua";

let defaultHeaders = {
    "Content-Type": "application/x-www-form-urlencoded",
};

export const fetchApi = (endpoint, params, ...callbacks) => {
    let { onSuccess, onFailed, onError } = callbacks[0];
    fetch(`${BASE_URL}/${endpoint}`, {
        method: "POST",
        headers: defaultHeaders,
        body: params,
    })
        .then((response) => {
            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                ) {
                    response.json().then((response) => {
                        if (response.status === "OK") {
                            onSuccess(response);
                        } else {
                            onFailed(response);
                        }
                    });
                } else {
                    response.text().then((response) => {
                        onSuccess(response);
                    });
                }
            } else {
                onFailed(response);
            }
        })
        .catch((error) => {
            onError(error);
        });
};
